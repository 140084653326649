<template>
    <MDBContainer fluid class="px-4">
        <MDBLoading v-model="loadingSpinner" />
        <MDBRow center class="align-items-center" style="height: 100vh" v-if="!loadingSpinner">
            <MDBCol sm="4">
                <MDBCard>
                    <MDBCardBody class="d-grid p-5">
                        <MDBCardTitle class="text-center mb-4">
                            <img src="@/assets/CSD-logo-black.png" height="80" aria-hidden="true" alt="CSD_Logo" />
                            <h5 class="mt-4">Activate User</h5>
                        </MDBCardTitle>
                        <MDBCardText v-if="!isLinkValid">
                            <div class="text-center mb-3">
                                <h5>This link is broken/invalid.</h5>
                            </div>
                        </MDBCardText>
                        <MDBCardText v-else-if="isLinkValid">
                            <form id="activate-user">
                                <MDBInput label="Email" v-model="form.email" disabled />
                                <MDBInput label="First Name" v-model="form.firstname" />
                                <MDBInput label="Last Name" v-model="form.lastname" />
                                <MDBInput label="Password" type="password" v-model="form.password" />
                                <MDBInput label="Confirm Password" type="password" v-model="confirmPassword"
                                    :helper="!isSamePassword ? 'Password does not match!' : ''" />
                                <password-meter @score="onScore" :password="form.password" />
                            </form>
                        </MDBCardText>
                        <div class="d-flex justify-content-center gap-4">
                            <MDBBtn v-if="isLinkValid" style="background-color: #fc5818;" color="primary"
                                @click="submit" :disabled="isSubmitBtnDisabled" size="md">Activate</MDBBtn>
                            <div class="text-center">
                                <router-link :to="{ name: 'Login' }" custom v-slot="{ href, navigate }">
                                    <MDBBtn tag="a" color="primary" @click.stop="navigate" :href="href" size="md">
                                        Back to Login
                                    </MDBBtn>
                                </router-link>
                            </div>
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBCol>
        </MDBRow>
        <MDBToast v-model="toastObject.state" :delay="2000" autohide position="top-right" appendToBody stacking
            width="350px" :color="toastObject.color" text="white" :icon="toastObject.icon">
            <template #title> {{ toastObject.title }}</template>
            {{ toastObject.message }}
        </MDBToast>
    </MDBContainer>
</template>

<script setup>
import PasswordMeter from "vue-simple-password-meter";
import { onBeforeMount, reactive, ref, computed } from "vue";
import axios from "axios";
import { useUsersStore } from "@/store/usersStore";
import { useRoute, useRouter } from "vue-router";
import {
    MDBToast,
    MDBLoading,
    MDBContainer,
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBRow,
    MDBCol,
} from "mdb-vue-ui-kit";

const usersStore = useUsersStore();
const route = useRoute();
const router = useRouter();
const link = route.params.link;

// const email = ref("");
const toastObject = ref({
    title: "",
    state: false,
    message: "",
    color: "",
    icon: "",
});

const loadingSpinner = ref(false);
const form = reactive({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
});
const confirmPassword = ref("");

const isSamePassword = computed(
    () => form.password === confirmPassword.value
);
const score = ref(null);
const isSubmitBtnDisabled = computed(
    () => !isSamePassword.value || form.firstname === "" || form.lastname === "" || score.value < 3
);
const onScore = (payload) => {
    score.value = payload.score;
};

const isLinkValid = ref(true);

const submit = () => {
    let formData = new FormData();
    formData.append("link", link);
    formData.append("email", usersStore.users.email);
    formData.append("lastname", form.lastname);
    formData.append("firstname", form.firstname);
    formData.append("password", form.password);

    loadingSpinner.value = true;
    axios
        .post("api/users/Register", formData)
        .then(function (response) {
            if (response.status == 200) {
                toastObject.value.state = true;
                toastObject.value.title = "Success!";
                toastObject.value.message = "Successfully submitted!";
                toastObject.value.color = "success";
                toastObject.value.icon = "fas fa-check fa-lg me-2";
                loadingSpinner.value = false;
                axios
                    .post("/api/users/login", {
                        email: form.email,
                        password: form.password
                    })
                    .then(function (response) {
                        if (response.status === undefined) {
                            toastObject.value.state = true;
                            toastObject.value.title = "Error!";
                            toastObject.value.message = "An error occurred during request";
                            toastObject.value.color = "danger";
                            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
                            loadingSpinner.value = false;
                        } else {
                            router.push("/Dashboard");
                        }
                    })
                    .catch(function () {
                        toastObject.value.state = true;
                        toastObject.value.title = "Error!";
                        toastObject.value.message = "An error occurred during request";
                        toastObject.value.color = "danger";
                        toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
                        loadingSpinner.value = false;
                    });
            } else {
                toastObject.value.state = true;
                toastObject.value.title = "Error!";
                toastObject.value.message = "An error occurred during request";
                toastObject.value.color = "danger";
                toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
                loadingSpinner.value = false;
            }
        })
        .catch(function () {
            toastObject.value.state = true;
            toastObject.value.title = "Error!";
            toastObject.value.message = "An error occurred during request";
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
            loadingSpinner.value = false;
        });
};

onBeforeMount(async () => {
    loadingSpinner.value = true;

    await axios
        .get("api/users/" + link + "/activate")
        .then(function (response) {
            isLinkValid.value = true;
            if (response == undefined || response.status != 200 || response.message) {
                isLinkValid.value = false;
            } else {
                form.email = response.data.email;
            }
            loadingSpinner.value = false;
        })
        .catch(function (error) {
            isLinkValid.value = false;
            toastObject.value.state = true;
            toastObject.value.message = "An error occurred during request" + error;
            toastObject.value.color = "danger";
            toastObject.value.icon = "fas fa-exclamation-circle fa-lg me-2";
        });
});

</script>

<style lang="scss" scoped>
#activate-user {
    display: grid;
    row-gap: 15px;
}

.btn-primary {
    background-color: #fc5818;
}

:deep(.form-outline .form-helper) {
    color: red;
}
</style>
